
import {request} from "@/network/request.js";
import qs from 'qs';

export default{
  // 查询-条件查询订单汇总信息
  findOrderReport(data){
    return request({
      method:'POST',
      url:'/report/order/findOrderReport',
      data
    })
  },
  // 查询-条件查询订单统计列表信息
  findOrderListReport(data){
    return request({
      method:'POST',
      url:'/report/order/findOrderListReport',
      data
    })
  }
}